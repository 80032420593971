var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5"},[(_vm.organizationsLoad)?_c('Loader'):_c('div',{staticClass:"container flex flex-col m-auto"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"},[_c('h3',{staticClass:"mt-10 text-lg font-medium text-gray-900 leading-6"},[_vm._v(" "+_vm._s(_vm.$i18n("Organizations"))+" ")]),_c('router-link',{staticClass:"inline-flex items-center justify-center inline-block px-4 py-2 my-5 text-base font-medium text-white bg-green-600 border border-transparent whitespace-nowrap rounded-md shadow-sm hover:bg-green-700",attrs:{"to":{ path: "organization/create" }}},[_vm._v(" "+_vm._s(_vm.$i18n("Create"))+" ")]),_c('div',{staticClass:"overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-200"},[_c('thead',{staticClass:"bg-gray-50"},[_c('tr',[_c('th',{staticClass:"px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$i18n("Name"))+" ")]),_c('th',{staticClass:"px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$i18n("Active"))+" ")]),_c('th',{staticClass:"px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$i18n("Users count"))+" ")]),_c('th',{staticClass:"relative px-6 py-3",attrs:{"scope":"col"}})])]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((_vm.organizations),function(ref){
var id = ref.id;
var name = ref.name;
var active = ref.active;
var users_count = ref.users_count;
return _c('tr',{key:(id + "_" + active)},[_c('td',{staticClass:"py-4 pr-6 whitespace-nowrap"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"ml-4"},[_c('div',{staticClass:"text-sm text-gray-500"},[_vm._v(" "+_vm._s(name)+" ")])])])]),_c('td',{staticClass:"py-4 pr-6 whitespace-nowrap"},[_c('t-toggle',{staticClass:"mr-5",attrs:{"checked":active},on:{"change":function (active) {
												_vm.updateStatus(id, active);
											}}})],1),_c('td',{staticClass:"py-4 pr-6 text-sm text-gray-500 whitespace-nowrap"},[_c('router-link',{staticClass:"ml-5 text-blue-600 underline hover:text-blue-900",attrs:{"to":{ path: ("users/" + id) }}},[_vm._v(" "+_vm._s(users_count)+" ")])],1),_c('td',{staticClass:"flex py-4 pr-6 text-sm font-medium text-right whitespace-nowrap"},[_c('router-link',{staticClass:"mr-5 text-blue-600 hover:text-blue-900",attrs:{"to":{ path: ("organization/update/" + id) }}},[_c('svg',{staticClass:"inline w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"}})])]),_c('a',{staticClass:"text-red-600 hover:text-red-900",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteOrganizationByIdMethod({ id: id, name: name })}}},[_c('svg',{staticClass:"inline w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])]),_c('router-link',{staticClass:"ml-5 text-blue-600 hover:text-blue-900",attrs:{"to":{ path: ("users/" + id) }}},[_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"}})])])],1)])}),0)])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }